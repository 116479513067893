import React from "react"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"
import Submenutreneri from "../../components/submenutreneri/submenutreneri"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import "./../treneri.scss"

import ImgTreneriTancibudek from "../../images/treneri/milos-tancibudek.jpg"

const MilosTancibudek = () => (

	<div>

		<Layout>
			<Seo title="Miloš Tancibudek" description="Miloš Tancibudek" />

			<div className="stk-container stk-container--top-margin">

				<Row>
					<Col xs="12" xl="9">
						<div class="stk-trener__header">
							<div>
								<img class="stk-trener__image" src={ImgTreneriTancibudek} alt="Miloš Tancibudek" />
							</div>
							<div>
								<h1 className="stk-h1">Miloš Tancibudek</h1>
								<p>Porotce a trenér 1. třídy</p>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--facebook" href="https://www.facebook.com/milos.tancibudek" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faFacebookF} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--instagram" href="https://www.instagram.com/milostancibudek/" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faInstagram} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--phone" href="tel:+420603436574" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faPhone} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--email" href="mailto:atelier13@volny.cz" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faEnvelope} />
								</a>
							</div>
						</div>
						<ul>
							<li>Mezinárodní taneční třída</li>
							<li>Mistr ČR v&nbsp;DanceShow</li>
							<li>Choreograf DanceShow</li>
							<li>3. místo na Mistrovství Evropy v&nbsp;DanceShow</li>
							<li>7. místo na Mistrovství světa v&nbsp;DanceShow</li>
							<li>Porotce a trenér 1.&nbsp;třídy</li>
							<li>Lektor tanečních kurzů a&nbsp;individuálních lekcí studia DANCEPERFECT</li>
							<li>Porotce soutěží TANEČNÍ SKUPINA ROKU</li>
							<li>Fakulta tělovýchovy a&nbsp;sportu UK - trenérské studium</li>
							<li>Semináře a lekce se špičkovými domácími i&nbsp;světovými trenéry</li>
						</ul>
					</Col>
					<Col xs="12" xl="3">
						<Submenutreneri></Submenutreneri>
					</Col>
				</Row>
			</div>
		</Layout>

	</div>

)

export default MilosTancibudek